@import '../tdi/css/lib';

@font-face {
  font-family: triodos-iconfont;
  src: url("../fonts/triodos-iconfont.eot?76961102");
  src: url("../fonts/triodos-iconfont.eot?76961102#iefix") format("embedded-opentype"), url("../fonts/triodos-iconfont.woff2?76961102") format("woff2"), url("../fonts/triodos-iconfont.woff?76961102") format("woff"), url("../fonts/triodos-iconfont.ttf?76961102") format("truetype"), url("../fonts/triodos-iconfont.svg?76961102#triodos-iconfont") format("svg");
  font-weight: 400;
  font-style: normal;
}

a {cursor:pointer;}
strong {font-weight:bold;}
em {font-style:italic;}
sup, sub {position: relative;top: -0.4em;vertical-align: baseline;}
sub { top: 0.4em; }
tr {height:20px;}
//section img {max-width:100%;height:auto;}

.marginbottom- {
  &none { margin-bottom:0; }
}
span.highlight {
  background-color:#d6ecff;
}

table.standard {
  // horizontal alignment
  th, td {text-align: right;}
  th:first-child, td:first-child {text-align: left;}
  th.notes, td.notes {text-align: center;}
  // explicit classes overrides defaults
  th, td {
    &.text-center {text-align: center;}
    &.text-right {text-align: right;}
    &.text-left {text-align: left;}
    &.text-justify {text-align: justify;}
  }

  // vertical alignment
  th {vertical-align: bottom;}
  td {vertical-align: top;}
  // explicit classes overrides defaults
  th, td {
    &.top {vertical-align: top;}
    &.bottom {vertical-align: bottom;}
    &.middle {vertical-align: middle;}
  }
}

@each $list-style in (
  none, square, circle, disc,                                                       // ul list styles
  decimal, decimal-leading-zero, lower-alpha, upper-alpha, lower-roman, upper-roman // ol list-styles
) {
  .list-style-#{$list-style} {
    list-style: $list-style;
  }
}

.search {
  .search-result {
    padding-bottom:10px;
    h3 {
      margin-bottom:5px;
    }
    .search-result-snippet {
      .snippet-searchresult-highlight {
        font-weight: bold;
      }
    }
  }
}








        /* Reset Font Weight variable values */
        :root {
          --type-weight--100: 100;
          --type-weight--200: 200;
          --type-weight--300: 300;
          --type-weight--400: 400;
          --type-weight--500: 500;
          --type-weight--600: 600;
          --type-weight--700: 700;
          --type-weight--800: 800;
          --type-weight--900: 900;
          }
          /* Set all font-weights with variables */
          body { font-weight: var(--type-body--font-weight); }
          h1, .h1 { font-weight: var(--type-h1--font-weight); }
          h2, .h2 { font-weight: var(--type-h2--font-weight); }
          h3, .h3 { font-weight: var(--type-h3--font-weight); }
          h4, .h4 { font-weight: var(--type-h4--font-weight); }
          /* Hide overflow on page without effecting sticky */
          .page-wrapper {
          overflow: clip;
          }
          /* Text link underline size and spacing */
          a {
          text-underline-position: under; 
          text-decoration-thickness: 0.1em;
          text-underline-offset: 0.05em;
          color: inherit;
          }
          /* Remove top margin on rich text first child */
          .w-richtext>:first-child {
          margin-top: 0;
          }
          /* Remove bottom margin of rich text last child */
          .w-richtext>:last-child, .w-richtext ol li:last-child, .w-richtext ul li:last-child {
          margin-bottom: 0;
          }
          /* Set layout system gap from variable */
          :root {
          --grid-gap-split: calc(var(--spacing-layout--row-col-gap)/2);
          }
          .row {
          margin-left: calc(var(--grid-gap-split) - var(--spacing-layout--row-col-gap));
          margin-right: calc(var(--grid-gap-split) - var(--spacing-layout--row-col-gap));
          /*margin-bottom: calc(0 - var(--spacing-layout--row-col-gap));*/
          }
          .col {
          padding-left: var(--grid-gap-split);
          padding-right: var(--grid-gap-split);
          /*margin-bottom: var(--spacing-layout--row-col-gap);*/
          }
          /* Reset margin bottom 0 utility after col gap set above */
          .u-mb-0 {
          margin-bottom: 0;
          }
          .noscroll {
          overflow: hidden;
          height: 100%;
          }
          .nav {
          overflow-x: auto;
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
          }
          h1, .h1 {
          font-size: clamp(4.6rem, 0.7947rem + 6.1376vw, 7.5rem);
          text-wrap: balance;
          }
          h2, .h2 {
          font-size: clamp(2.625rem, 0.23rem + 4.9896vw, 5.625rem);
          }
          h3, .h3 {
          font-size: clamp(1.75rem, 1.0515rem + 1.4553vw, 2.625rem);
          text-wrap: balance;
          }
          .paragraph-large {
          font-size: clamp(1.125rem, 0.625rem + 1.0417vw, 1.75rem);
          }
          blockquote {
          font-size: clamp(1.75rem, 1.0515rem + 1.4553vw, 2.625rem);
          }
          .quote {
          font-size: clamp(1.3125rem, 0.7625rem + 1.1458vw, 2rem);
          }
          .data_title {
          font-size: clamp(2.6875rem, 0.3375rem + 4.8958vw, 5.625rem);
          }
          .quote_text-big {
          font-size: clamp(2.5rem, -0.2942rem + 5.8212vw, 6rem);
          }
          .text-wrap-balance {
          text-wrap: balance
          }
          .hero_video-play:hover .hero_video-text {
          opacity: 1;
          display: block;
          }

blockquote {
  quotes: "\201C" "\201D" ;
  margin-bottom: 30px;
  
  &::before {
    position: relative;
    content: open-quote;
  }
  &::after {
    position: relative;
    content: close-quote;
  }
}          

p {
  margin-bottom: var(--type-body--line-height);
}

.block_content p {
  margin-bottom: 0px;
}






.intro_text {
  margin-bottom: 3rem;
}


.privacy_title {
  //grid-column-gap: 1rem;
  //grid-row-gap: 1rem;
  //flex-direction: column;
  //margin-bottom: 4rem;
  display: block;
  height: 200px;
}


.privacy {


  .white-box {
    background: #fff;
    padding: 45px 25px;
    margin-bottom: 32px;
  }
 
  h5 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
  }

  p {
    margin-bottom: 30px;
  }

  h2 {
    margin-top: 60px;
    font-size: 30px;
    line-height: 36px;
    font-weight: bold;
  }

  h3 {
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      margin-bottom: 10px;
  }
  h2, h3, h4, h5, h6 {
    margin: 0 0 5px;
  }

  @media screen and (min-width: 768px) {
    h3 {
        font-size: 24px;
        line-height: 32px;
    }
  }

  h3.intro_text {
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 1.4em;
    margin-bottom: 3rem;
  }

  .line {
    display: block;
    border-bottom: 1px solid rgba(0, 0, 107, 0.3);
    margin: 48px 0;
  }

  .columns {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 12px;
  }
  .columns > * {
    margin-bottom: 40px;
  }


  @media (min-width: 667px) {
    .columns .col--sm-12 {
      width: calc((100% - (1 - 1) * 30px)/ 1);
    }
    .columns .col--sm-11 {
      width: calc((100% - (1.0909090909090908 - 1) * 30px)/ 1.0909090909090908);
    }
    .columns .col--sm-10 {
      width: calc((100% - (1.2 - 1) * 30px)/ 1.2);
    }
    .columns .col--sm-9 {
      width: calc((100% - (1.3333333333333333 - 1) * 30px)/ 1.3333333333333333);
    }
    .columns .col--sm-8 {
      width: calc((100% - (1.5 - 1) * 30px)/ 1.5);
    }
    .columns .col--sm-7 {
      width: calc((100% - (1.7142857142857142 - 1) * 30px)/ 1.7142857142857142);
    }
    .columns .col--sm-6 {
      width: calc((100% - (2 - 1) * 30px)/ 2);
    }
    .columns .col--sm-5 {
      width: calc((100% - (2.4 - 1) * 30px)/ 2.4);
    }
    .columns .col--sm-4 {
      width: calc((100% - (3 - 1) * 30px)/ 3);
    }
    .columns .col--sm-3 {
      width: calc((100% - (4 - 1) * 30px)/ 4);
    }
    .columns .col--sm-2 {
      width: calc((100% - (6 - 1) * 30px)/ 6);
    }
    .columns .col--sm-1 {
      width: calc((100% - (12 - 1) * 30px)/ 12);
    }
    .columns .col--sm-offset-left-12 {
      margin-left: calc((100% + 30px)/ 1);
    }
    .columns .col--sm-offset-left-11 {
      margin-left: calc((100% + 30px)/ 1.0909090909090908);
    }
    .columns .col--sm-offset-left-10 {
      margin-left: calc((100% + 30px)/ 1.2);
    }
    .columns .col--sm-offset-left-9 {
      margin-left: calc((100% + 30px)/ 1.3333333333333333);
    }
    .columns .col--sm-offset-left-8 {
      margin-left: calc((100% + 30px)/ 1.5);
    }
    .columns .col--sm-offset-left-7 {
      margin-left: calc((100% + 30px)/ 1.7142857142857142);
    }
    .columns .col--sm-offset-left-6 {
      margin-left: calc((100% + 30px)/ 2);
    }
    .columns .col--sm-offset-left-5 {
      margin-left: calc((100% + 30px)/ 2.4);
    }
    .columns .col--sm-offset-left-4 {
      margin-left: calc((100% + 30px)/ 3);
    }
    .columns .col--sm-offset-left-3 {
      margin-left: calc((100% + 30px)/ 4);
    }
    .columns .col--sm-offset-left-2 {
      margin-left: calc((100% + 30px)/ 6);
    }
    .columns .col--sm-offset-left-1 {
      margin-left: calc((100% + 30px)/ 12);
    }
    .columns .col--sm-offset-right-12 {
      margin-right: calc((100% + 30px)/ 1);
    }
    .columns .col--sm-offset-right-11 {
      margin-right: calc((100% + 30px)/ 1.0909090909090908);
    }
    .columns .col--sm-offset-right-10 {
      margin-right: calc((100% + 30px)/ 1.2);
    }
    .columns .col--sm-offset-right-9 {
      margin-right: calc((100% + 30px)/ 1.3333333333333333);
    }
    .columns .col--sm-offset-right-8 {
      margin-right: calc((100% + 30px)/ 1.5);
    }
    .columns .col--sm-offset-right-7 {
      margin-right: calc((100% + 30px)/ 1.7142857142857142);
    }
    .columns .col--sm-offset-right-6 {
      margin-right: calc((100% + 30px)/ 2);
    }
    .columns .col--sm-offset-right-5 {
      margin-right: calc((100% + 30px)/ 2.4);
    }
    .columns .col--sm-offset-right-4 {
      margin-right: calc((100% + 30px)/ 3);
    }
    .columns .col--sm-offset-right-3 {
      margin-right: calc((100% + 30px)/ 4);
    }
    .columns .col--sm-offset-right-2 {
      margin-right: calc((100% + 30px)/ 6);
    }
    .columns .col--sm-offset-right-1 {
      margin-right: calc((100% + 30px)/ 12);
    }
  }


  .accordion {
    border-bottom: 1px solid rgba(0, 0, 107, 0.3);
  }

  .accordion:hover {
    border-bottom-color: #00006b;
  }

  .accordion:hover .accordion-trigger:after {
    border-color: #00006b;
  }

  .accordion.accordion-opened .accordion-trigger:after {
    border-color: #00006b;
    background: #00006b;
    color: #fff;
    transform: rotate(45deg);
  }

  .accordion-heading {
    margin: 0;
  }

  .accordion-trigger {
    position: relative;
    display: block;
    font-size: 24px;
    line-height: 33px;
    font-weight: 400;
    color: #00006b;
    padding: 24px 72px 24px 0;
    border: 0;
  }

  .accordion-trigger:after {
    content: '\E804';
    font-family: triodos-iconfont;
    font-size: 40px;
    line-height: 40px;
    position: absolute;
    right: 24px;
    top: 20px;
    border: 2px solid rgba(0, 0, 107, 0.3);
    border-radius: 50%;
    transition: transform .25s;
    transform-origin: 50% 50%;
  }

  .accordion-content {
    padding: 24px 0 24px 0;
  }

  .accordion-content > :last-child {
    margin-bottom: 0;
  }

  .accordion + :not(.accordion) {
    margin-top: 80px;
  }

}

.cookie-layer--active .primary-wrapper {
  transition-delay: 0s !important;
}

.cookie-layer--active .cookie-layer {
  transition-delay: 1s !important;
}

.cookie-layer--active.cookie-layer--hidden .primary-wrapper {
  transition-delay: 1s !important;
}

.cookie-layer--active.cookie-layer--hidden .cookie-layer {
  transition-delay: 0s !important;
}

.cookie-layer {
  display: none;
  position: fixed;
  padding-top: 16px;
  transition: margin-top 1s;
  z-index: 1000;

  .arrow-link:hover:before {
    transform: translate(3px, 0);
  }
  .arrow-link:before {
    content: '>';
    display: inline-block;
    margin-right: 10px;
    transition: transform .1s ease-in;
}
}

.cookie-layer.hide {
  margin-top: -100%;
}

.cookie-layer--active {
  display: block;
}

.cookie-layer__content {
  background: #fffadc;
  padding: 16px;
  border-radius: 6px;
}

.cookie-layer__text {
  margin-bottom: 14px;
}

.cookie-layer__link {
  display: inline-block;
  margin-bottom: 10px;
  border: 0;
  font-weight: 700;
}

.cookie-layer__button {
  display: block;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .cookie-layer {
    padding-top: 28px;
  }
  .cookie-layer__content {
    padding: 28px;
  }
  .cookie-layer__link {
    margin: 0;
  }
  .cookie-layer__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}







.privacyboard__single-content-input {
  position: relative;
}

.privacyboard__single-content-label {
  display: block;
  text-align: right;
  font-size: 16px;
  line-height: 2.2em;
}

.form__checkboxtoggle {
  float: right;
  height: 30px;
  font-size: 18px;
  line-height: 1.15;
  font-weight: 400;
}

.form__checkboxtoggle label {
  position: relative;
  display: inline-block;
  height: 30px;
  padding: 0 12px;
  overflow: hidden;
  background-color: #00006b;
  border-radius: 15px;
  line-height: 30px;
  color: #fff;
  text-align: right;
  transition: background-color .25s ease-in-out;
  cursor: pointer;
}

.form__checkboxtoggle label:after {
  -webkit-transition: all .25s ease-in-out;
  background-color: #fff;
  border-radius: 100%;
  content: "";
  display: block;
  height: 26px;
  left: 2px;
  position: absolute;
  top: 2px;
  transition: all .25s ease-in-out;
  width: 26px;
}

.form__checkboxtoggle-option.first {
  padding-left: 26px;
}

.form__checkboxtoggle-option.second {
  display: none;
  padding-right: 24px;
  text-align: left;
  color: #00006b;
}

.form__checkboxtoggle input:checked + label .form__checkboxtoggle-option {
  display: none;
}

.form__checkboxtoggle input:checked + label .form__checkboxtoggle-option.second {
  display: block;
}

.form__checkboxtoggle input:checked + label {
  background: #4bf0b9;
}

.form__checkboxtoggle input:checked + label:after {
  width: 24px;
  height: 24px;
  top: 3px;
  left: calc(100% - 27px);
}

.visuallyhidden {
  display: block;
  width: 0;
  height: 0;
  visibility: hidden;
}


